import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import logo from "../assets/image/logodiabella.png";
import flower from "../assets/image/kasia.png";
import { useInView } from "react-intersection-observer";
import { PortableText } from "@portabletext/react";
import { Link, Button, animateScroll as scroll, scrollSpy } from "react-scroll";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

function Header() {
  const [allPostsData, setAllPosts] = useState(null);
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const components = {
    types: {
      image: (props) => (
        <figure>
          <img src={urlFor(props.value.asset).width(200).url()} />
        </figure>
      ),
      h1: ({ props }) => <h1 className="text-2xl">{props}</h1>,
    },
    block: {
      // Ex. 1: customizing common block types
      h1: ({ children }) => <h1 className="text-2xl">{children}</h1>,
      h2: ({ children }) => (
        <h2 className="text-3xl text-white leading-snug dark:text-gray-200 md:text-4xl">
          {children}
        </h2>
      ),
      normal: ({ children }) => (
        <p className="mt-4 text-lg text-gray-500 dark:text-gray-300">
          {children}
        </p>
      ),
      blockquote: ({ children }) => (
        <blockquote className="border-l-purple-500">{children}</blockquote>
      ),

      // Ex. 2: rendering custom styles
      customHeading: ({ children }) => (
        <h2 className="text-3xl leading-snug text-gray-800 dark:text-gray-200 md:text-4xl">
          {children}
        </h2>
      ),
    },
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "header"]{
        title,
        bodytitel,
        body,
        text,
        slug,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);
  return (
    <div>
      <header className="color1 text-gray-600 body-font">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <a
            href="https://diabella.nl"
            className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
          >
            <div className=" w-64 h-32 md:w-64 md:h-32  inline-flex items-center justify-center rounded-full">
              <img className="logo w-64 md:w-64" src={logo} />
            </div>
            <span className="ml-3 text-xl text-white logo"></span>
          </a>
          <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400	flex flex-wrap items-center text-base justify-center">
            <NavLink to="/facial" className="nav-item nav-link contactItem">
              Facial
            </NavLink>
            <NavLink to="/pedicure" className="nav-item nav-link contactItem">
              Pedicure
            </NavLink>
            <NavLink to="/body" className="nav-item nav-link contactItem">
              Body
            </NavLink>
            <NavLink to="/contact" className="nav-item nav-link contactItem">
              Contact
            </NavLink>
          </nav>
          <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
            <a
              href="mailto:info@diabella.nl"
              className=" contactItem contactItem__mail"
              target="_blank"
            >
              {" "}
              info@diabella.nl
            </a>
            <a
              href="tel:06 26646916"
              title="Bel 06 26646916"
              className=" contactItem contactItem__phone"
              target="_blank"
            >
              {" "}
              06 26646916
            </a>
          </nav>
          <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
            {/* 
            <a
              href="https://www.facebook.com/profile.php?id=100054451811032"
              target="_blank"
              className="text-gray-500 hover:invert"
            >
              <svg
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>

            <a
              href="https://www.instagram.com/kasia_kijewska/"
              target="_blank"
              className="ml-3 text-gray-500 hover:invert"
            >
              <svg
                fill="none"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg>
            </a>
            <a
              href="https://nl.linkedin.com/in/kasia-kijewska-35044435"
              target="_blank"
              className="ml-3 text-gray-500 hover:invert"
            >
              <svg
                fill="white"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="none"
                  d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                ></path>
                <circle cx="4" cy="4" r="2" stroke="none"></circle>
              </svg>
            </a>


            <nav className="navbar navbar-default navbar-fixed-top">
              <div className="container-fluid">
                <div
                  className="collapse navbar-collapse"
                  id="bs-example-navbar-collapse-1"
                >
                  <ul className="nav navbar-nav">
                    <li>
                      <Link
                        activeClass="active"
                        className="test1"
                        to="test1"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Test 1
                      </Link>
                    </li>
                    <li>
                      <Link
                        activeClass="active"
                        className="test2"
                        to="test2"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Test 2
                      </Link>
                    </li>
                    <li>
                      <Link
                        activeClass="active"
                        className="test3"
                        to="test3"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Test 3
                      </Link>
                    </li>
                    <li>
                      <Link
                        activeClass="active"
                        className="test4"
                        to="test4"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Test 4
                      </Link>
                    </li>

                   
                    <li>
                      <Link
                        activeClass="active"
                        className="test5"
                        to="test5"
                        spy={true}
                        smooth={true}
                        duration={500}
                        delay={1000}
                      >
                        Test 5 ( delay )
                      </Link>
                    </li>
                    <li>
                      <Link
                        activeClass="active"
                        className="test6"
                        to="anchor"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Test 6 (anchor)
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <a onClick={() => scroll.scrollTo(100)}>Scroll To 100!</a>
                    </li>
                    <li>
                      {" "}
                      <a onClick={() => scroll.scrollToBottom()}>
                        Scroll To Bottom
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a onClick={() => scroll.scrollMore(500)}>
                        Scroll 500 More!
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        onClick={() => scroll.scrollMore(1000, { delay: 1500 })}
                      >
                        Scroll 1000 More! ( delay ){" "}
                      </a>
                    </li>
                    <li>
                      <Link
                        activeClass="active"
                        className="test8"
                        to="same"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Same target
                      </Link>
                    </li>
                    <li>
                      <Link
                        activeClass="active"
                        className="test9"
                        to="same"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        Same target
                      </Link>
                    </li>
                    <li>
                      <a
                        className="test1"
                        to="test1"
                        onClick={() => this.scrollTo()}
                      >
                        Scroll to element
                      </a>
                    </li>
                    <li>
                      <a
                        className="test1"
                        to="test1"
                        onClick={() => this.scrollToWithContainer()}
                      >
                        Scroll to element within container
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </nav>*/}
          </span>
        </div>
      </header>

      {/*  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#242424"
          fill-opacity="1"
          d="M0,256L80,261.3C160,267,320,277,480,234.7C640,192,800,96,960,53.3C1120,11,1280,21,1360,26.7L1440,32L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
        ></path>
</svg>*/}

      {/*
      <section className="text-gray-600 body-font ">
        {allPostsData &&
          allPostsData.map((header, index) => (
            <div
              key={index}
              className="container px-5 py-24 mx-auto flex flex-wrap "
            >
              <div
                ref={ref}
                className={` box flex flex-wrap w-full ${
                  inView ? "actives" : ""
                }`}
              >
                <div className="lg:w-2/5  md:pr-10 md:py-6">
                  <div className="flex relative pb-12">
                    <div className=" lg:flex-grow lg:w-1/2  xl:pr-24  flex flex-col md:items-start md:text-left mb-0 items-center text-center">
                      <img className="logo" src={flower} width="140px" />
                      <h2 className="text-3xl leading-snug text-gray-800 dark:text-gray-200 md:text-4xl">
                        {header.bodytitel}
                      </h2>
                      <p className="mt-4 text-lg text-gray-500 dark:text-gray-300">
                        {" "}
                        {header.body}
                      </p>
                      <PortableText
                        value={header.text}
                        components={components}
                      />
                    </div>
                  </div>
                </div>

                {header.mainImage && (
                  <img
                    src={urlFor(header.mainImage).width().url()}
                    className="lg:w-3/5 md:w-1/2 object-cover object-center md:mt-0 mt-12"
                    alt={header.title}
                  />
                )}
              </div>
            </div>
          ))}
      </section>
                */}
    </div>
  );
}

export default Header;
