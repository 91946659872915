import React, { useReducer, useState, useEffect } from "react";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import { PortableText } from "@portabletext/react";
import ReCAPTCHA from "react-google-recaptcha";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet-async";

const Joi = require("joi");

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const recaptchaRef = React.createRef();

const ContactForm = (props) => {
  const [allPostsData, setAllPosts] = useState(null);
  const [loading, setLoading] = useState(false);

  const components = {
    types: {
      image: (props) => (
        <figure>
          <img src={urlFor(props.value.asset).width(200).url()} />
        </figure>
      ),
      h1: ({ props }) => <h1 className="text-2xl">{props}</h1>,
    },
    block: {
      // Ex. 1: customizing common block types
      h1: ({ children }) => <h1 className="text-2xl">{children}</h1>,
      h2: ({ children }) => (
        <h2 className="text-3xl text-white leading-snug dark:text-gray-200 md:text-4xl">
          {children}
        </h2>
      ),
      blockquote: ({ children }) => (
        <blockquote className="border-l-purple-500">{children}</blockquote>
      ),

      // Ex. 2: rendering custom styles
      customHeading: ({ children }) => (
        <h2 className="text-3xl leading-snug text-gray-800 dark:text-gray-200 md:text-4xl">
          {children}
        </h2>
      ),
    },
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "contactform"]{
        title,
        body,
        slug,
        text,
        image,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);

    setTimeout(() => {
      setLoading(true);
    }, 1000);
  }, []);

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    telefoon: "",
    message: "",
    messagex: "",
  });
  const initialFormErrorState = {
    nameError: "",
    emailError: "",
    telefoonError: "",
    messageError: "",
    messagexError: "",
  };

  const reducer = (state, action) => {
    return {
      ...state,
      [action.name]: action.value,
    };
  };

  const [state, dispatch] = useReducer(reducer, initialFormErrorState);

  const objectSchema = {
    name: Joi.string().min(3).required().messages({
      "string.base": "'Naam' moet een string zijn",
      "string.empty": "'Naam' mag niet leeg zijn",
      "string.min": "'Naam' lengte moet minstens {{#limit}} tekens lang zijn",
      "any.required": `'Naam' is een verplicht veld`,
    }),

    email: Joi.string()
      .email({
        minDomainSegments: 2,
        tlds: { allow: ["com", "nl", "pl"] },
      })
      .messages({
        "string.base": "'Email' moet een string zijn",
        "string.empty": "'Email' mag niet leeg zijn",
        "string.email": "'Email' moet een geldig e-mailadres zijn",
        "string.min":
          "'Email' lengte moet minstens {{#limit}} tekens lang zijn",
        "any.required": `'Email' is een verplicht veld`,
      }),

    telefoon: Joi.string()
      .min(10)
      .regex(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/)
      .required()
      .messages({
        "string.pattern.base":
          '"Telefoon" met waarde {:[.]} is niet een geldig telefoonnummer',
        "string.base": "'Telefoon' moet een string zijn",
        "string.empty": "'Telefoon' mag niet leeg zijn",
        "string.min":
          "'Telefoon' lengte moet minstens {{#limit}} tekens lang zijn",
        "any.required": `'Telefoon' is een verplicht veld`,
      }),

    message: Joi.string().min(3).required().messages({
      "string.base": "'Bericht' moet een string zijn",
      "string.empty": "'Bericht' mag niet leeg zijn",
      "string.min":
        "'Bericht' lengte moet minstens {{#limit}} tekens lang zijn",
      "any.required": `'Bericht' is een verplicht veld`,
    }),
    messagex: Joi.string().messages({
      "string.base": "'Naam' moet een string zijn",
      "string.empty": "'Naam' mag niet leeg zijn",
      "string.min": "'Naam' lengte moet minstens {{#limit}} tekens lang zijn",
      "any.required": `'Naam' is een verplicht veld`,
    }),
  };

  const handleChange = (e, errorFieldName) => {
    setUserData((currentData) => {
      return {
        ...currentData,
        [e.target.id]: e.target.value,
      };
    });
    const propertySchema = Joi.object({
      [e.target.id]: objectSchema[e.target.id],
    });

    const result = propertySchema.validate({ [e.target.id]: e.target.value });
    result.error == null
      ? dispatch({
          name: errorFieldName,
          value: "",
        })
      : dispatch({
          name: errorFieldName,
          value: result.error.details[0].message,
        });
  };

  function onChange(value) {
    // console.log("Captcha value:", value);
  }

  const [status, setStatus] = useState("Verzenden");
  const [resulta, setResulta] = useState("");

  const handleSubmit = async (e, nameError) => {
    e.preventDefault();

    /*
    const schema = Joi.object({
      name: Joi.string().alphanum().min(3).max(30).required(),

      email: Joi.string().email({
        minDomainSegments: 2,
        tlds: { allow: ["com", "net"] },
      }),

      message: Joi.string().alphanum().min(3).max(30).required(),
    });
*/
    const details = {
      name: userData.name,
      email: userData.email,
      telefoon: userData.telefoon,
      message: userData.message,
    };

    const propertySchema = Joi.object(objectSchema);

    const validation = propertySchema.validate(details, { abortEarly: false });

    if (validation.error) {
      const many = validation.error.details.length;

      const step = "";
      for (let step = 0; step < many; step++) {
        // Runs 5 times, with values of step 0 through 4.

        if (validation.error.details[step].context["label"] === "name") {
          dispatch({
            name: "nameError",
            value: validation.error.details[step].message,
          });
        }
        if (validation.error.details[step].context["label"] === "email") {
          dispatch({
            name: "emailError",
            value: validation.error.details[step].message,
          });
        }
        if (validation.error.details[step].context["label"] === "telefoon") {
          dispatch({
            name: "telefoonError",
            value: validation.error.details[step].message,
          });
        }
        if (validation.error.details[step].context["label"] === "message") {
          dispatch({
            name: "messageError",
            value: validation.error.details[step].message,
          });
        }
      }
    }
    /*
    if (validation.error) {
      dispatch({
        name: "nameError",
        value: validation.error.details[0].message,
      });
      dispatch({
        name: "emailError",
        value: validation.error.details[1].message,
      });
      dispatch({
        name: "messageError",
        value: validation.error.details[2].message,
      });
    }


    if (userData.messagex.trim().length !== 0) {
      console.log("input value is NOT empty");
    } else {
      console.log("input value is empty");
    }
    */
    recaptchaRef.current.execute();
    setStatus("Verzenden");
    if (!validation.error && userData.messagex.trim().length === 0) {
      setStatus("Bezig met verzenden...");
      let response = await fetch("https://chane.nl/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(userData),
      });
      setStatus("Verzenden");

      const result = await response.json();
      if (result.status === "Message Sent")
        return (
          setUserData({
            name: "",
            email: "",
            telefoon: "",
            message: "",
            messagex: "",
          }),
          setResulta(
            "Bedankt voor je bericht. Ik zal zo snel mogelijk contact met je opnemen."
          )
        );
    }
  };

  if (!loading) {
    return <div></div>;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Contact - Diabella</title>
        <link rel="canonical" href="https://diabella.nl/contact/" />
        <meta property="og:locale" content="nl_NL" />
        <meta
          name="description"
          content="Heb je een vraag of wil je een aanmelding doen voor een behandeling."
        />
        <meta property="og:url" content="https://diabella.nl/contact/" />
        <meta property="og:site_name" content="Diabella" />
      </Helmet>
      <section className="containercontact">
        <div className="container px-8 py-16 mx-auto lg:h-128 lg:space-x-8 lg:flex lg:items-center">
          <div
            ref={ref}
            className={`box w-full text-center lg:text-left lg:w-1/2 lg:-mt-8 ${
              inView ? "actives" : ""
            }`}
          >
            {allPostsData &&
              allPostsData.map((contactform, index) => (
                // <Link to={"/" + klip.slug.current} key={klip.slug.current}>
                <span key={index}>
                  {contactform.mainImage && (
                    <img
                      src={urlFor(contactform.mainImage).width(200).url()}
                      className="w-10 h-10"
                      alt={contactform.title}
                    />
                  )}

                  <PortableText
                    value={contactform.text}
                    components={components}
                  />
                  <span>
                    <p className="mt-4 text-lg text-mariels-default dark:text-gray-300">
                      {contactform.body}
                    </p>
                  </span>
                </span>
                //  </Link>
              ))}
          </div>

          <div
            ref={ref}
            className={`box w-full mt-4 lg:mt-0 lg:w-1/2 ${
              inView ? "actives" : ""
            }`}
          >
            <form id="contact" onSubmit={handleSubmit}>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="6Lel-_wUAAAAAAhFAo3YEVVCsATLT_2tBY66vn3E"
                onChange={onChange}
              />
              <label className=" block mb-6">
                <span className="text-mariels-default">Naam</span>
                <input
                  type="text"
                  id="name"
                  className="
                block
                w-full
                mt-1
                border-gray-300
                shadow-sm
                focus:bg-white
                active:bg-white
 
              "
                  placeholder="Voor- en achternaam invoeren"
                  onChange={(value) => handleChange(value, "nameError")}
                  value={userData.name}
                />
                {state.nameError ? (
                  <div
                    className="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-lg dark:bg-gray-700 dark:text-gray-300"
                    role="alert"
                  >
                    <span className="block sm:inline">{state.nameError}</span>
                  </div>
                ) : (
                  " "
                )}
              </label>
              <label className="block mb-6">
                <span className="text-mariels-default">Email</span>
                <input
                  name="email"
                  id="email"
                  type="email"
                  className="
        block
        w-full
        mt-1
        border-gray-300
        shadow-sm
        focus:bg-white
        active:bg-white
      "
                  placeholder="Emailadres invoeren"
                  onChange={(value) => handleChange(value, "emailError")}
                  value={userData.email}
                />
                {state.emailError ? (
                  <div
                    className="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-lg dark:bg-gray-700 dark:text-gray-300"
                    role="alert"
                  >
                    <span className="block sm:inline">{state.emailError}</span>
                  </div>
                ) : (
                  " "
                )}
              </label>
              <label className="block mb-6">
                <span className="text-mariels-default">Telefoon</span>
                <input
                  name="telefoon"
                  id="telefoon"
                  type="tel"
                  className="
        block
        w-full
        mt-1
        border-gray-300
        shadow-sm
        focus:bg-white
        active:bg-white
      "
                  placeholder="Telefoon invoeren"
                  onChange={(value) => handleChange(value, "telefoonError")}
                  value={userData.telefoon}
                />
                {state.telefoonError ? (
                  <div
                    className="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-lg dark:bg-gray-700 dark:text-gray-300"
                    role="alert"
                  >
                    <span className="block sm:inline">
                      {state.telefoonError}
                    </span>
                  </div>
                ) : (
                  " "
                )}
              </label>
              <label className="block mb-6">
                <span className="ext-mariels-default">Bericht</span>
                <textarea
                  name="message"
                  id="message"
                  className="
        block
        w-full
        mt-1
        border-gray-300
        shadow-sm
        focus:bg-white
        active:bg-white
      "
                  rows="3"
                  placeholder="Waar kan ik je mee helpen?"
                  onChange={(value) => handleChange(value, "messageError")}
                  value={userData.message}
                ></textarea>
                {state.messageError ? (
                  <div
                    className="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-lg dark:bg-gray-700 dark:text-gray-300 "
                    role="alert"
                  >
                    <span className="block sm:inline ">
                      {state.messageError}
                    </span>
                  </div>
                ) : (
                  " "
                )}
              </label>
              <label htmlFor="messagex">Messagex </label>
              <input
                id="messagex"
                name="messagex"
                size="40"
                type="text"
                onChange={handleChange}
              />

              <div className="mb-6">
                <button
                  type="submit"
                  className="
        h-10
        px-5
        text-white bg-gradient-to-r bg-kasiagreen-default hover:bg-kasiayellow-default hover:text-white py-2 px-4 rounded mr-2"
                  //</div>  disabled={
                  //  !userData.name || !userData.email || !userData.message
                  // bg-gradient-to-r from-kasiagreen-default to-kasiayellow-default hover:from-kasiayellow-default hover:to-kasiagreen-default
                  // bg-kasiabutton-default hover:bg-kasiagreen-default
                  //}
                >
                  {status}
                </button>
              </div>
              <div>
                <p className="mt-4 text-lg text-black dark:text-gray-300">
                  {resulta}
                </p>
              </div>
            </form>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ContactForm;
