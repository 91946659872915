import React from "react";
import { BrowserRouter, Routes, Switch, Route } from "react-router-dom";
import Header from "./components/Header.js";
import Home from "./components/Home.js";
import Allblockspedicure from "./components/Pedicure.js";
import Allblocks from "./components/Facial.js";
import Body from "./components/Body.js";
import Contact from "./components//ContactForm.js";
import { Footer } from "./components//footer.js";
import { Footerend } from "./components//Footerend.js";
import { HelmetProvider } from "react-helmet-async";
//<Route path="*" element={<Home />} />
function App() {
  return (
    <BrowserRouter>
      <div>
        <Header />
        <HelmetProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Pedicure" element={<Allblockspedicure />} />
            <Route path="/Facial" element={<Allblocks />} />
            <Route path="/Body" element={<Body />} />
            <Route path="/Contact" element={<Contact />} />
          </Routes>
        </HelmetProvider>
        <Footer />
        <Footerend />
      </div>
    </BrowserRouter>
  );
}
export default App;
