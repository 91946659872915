import React, { useEffect, useState } from "react";
import map from "../../assets/image/map.svg";
import { useInView } from "react-intersection-observer";
import sanityClient from "../../client.js";

function Fpartfour() {
  const [allPostsData, setAllPosts] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "adres"]{
        title,
        body,
        slug
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);

    setTimeout(() => {
      setLoading(true);
    }, 1000);
  }, []);

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  if (!loading) {
    return <div></div>;
  }
  return (
    <div ref={ref} className={`boxx ${inView ? "activess" : ""}`}>
      <div className=" w-20 h-20 inline-flex items-center justify-center rounded-full bg-mariel-default text-indigo-500 mb-5 flex-shrink-0">
        <a
          href="https://www.google.nl/maps/place/Diabella+Beautycare/@51.9212122,4.4878261,17z/data=!3m1!4b1!4m6!3m5!1s0x47c433596e40fc5b:0xdbb23fa816e479ff!8m2!3d51.9212089!4d4.4900148!16s%2Fg%2F1tfzwnx9"
          target="_blank"
          className="hover:invert"
        >
          <img className="mapme" src={map} width="70px" />
        </a>
      </div>
      <div className="flex-grow">
        {allPostsData &&
          allPostsData.map((adres, index) => (
            //<Link to={"/" + post.slug.current} key={post.slug.current}>
            <span key={index}>
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                {adres.title}
              </h2>

              <p className="leading-relaxed text-base">{adres.body}</p>
            </span>
            // </Link>
          ))}
      </div>
    </div>
  );
}
export default Fpartfour;
