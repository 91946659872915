import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import sanityClient from "../../client.js";

function Fpartone() {
  const [allPostsData, setAllPosts] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "contact"]{
        title,
        body,
        slug
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);

    setTimeout(() => {
      setLoading(true);
    }, 1000);
  }, []);
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  if (!loading) {
    return <div></div>;
  }

  return (
    <div ref={ref} className={`boxx ${inView ? "activess" : ""}`}>
      {allPostsData &&
        allPostsData.map((contact, index) => (
          //<Link to={"/" + post.slug.current} key={post.slug.current}>
          <span key={index}>
            <h2 className="tsm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
              {contact.title}
            </h2>

            <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">
              {contact.body}
            </p>
          </span>
          // </Link>
        ))}

      <div className="flex mt-6 justify-center">
        <div className="w-16 h-1 rounded-full bg-mariels-default inline-flex"></div>
      </div>
    </div>
  );
}
export default Fpartone;
