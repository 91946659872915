import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { PortableText } from "@portabletext/react";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

function Footerimage() {
  const [allPostsData, setAllPosts] = useState(null);
  const [loading, setLoading] = useState(false);

  const components = {
    types: {
      image: (props) => (
        <figure>
          <img src={urlFor(props.value.asset).width(200).url()} />
        </figure>
      ),
      h1: ({ props }) => <h1 className="text-2xl">{props}</h1>,
    },
    block: {
      // Ex. 1: customizing common block types
      h1: ({ children }) => <h1 className="text-2xl">{children}</h1>,
      h2: ({ children }) => (
        <h2 className="text-3xl text-white leading-snug dark:text-gray-200 md:text-4xl">
          {children}
        </h2>
      ),
      blockquote: ({ children }) => (
        <blockquote className="border-l-purple-500">{children}</blockquote>
      ),

      // Ex. 2: rendering custom styles
      customHeading: ({ children }) => (
        <h2 className="text-3xl leading-snug text-gray-800 dark:text-gray-200 md:text-4xl">
          {children}
        </h2>
      ),
    },
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "footerimage"]{
        title,
        body,
        slug,
        text,
        image,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);

    setTimeout(() => {
      setLoading(true);
    }, 1000);
  }, []);

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  if (!loading) {
    return <div>...</div>;
  }

  return (
    <div ref={ref} className={`boxx  ${inView ? "activess" : ""}`}>
      {allPostsData &&
        allPostsData.map((footerimage, index) => (
          // <Link to={"/" + klip.slug.current} key={klip.slug.current}>
          <span key={index}>
            {footerimage.mainImage && (
              <img
                src={urlFor(footerimage.mainImage).width().url()}
                className="w-full"
                alt={footerimage.title}
              />
            )}

            <PortableText value={footerimage.text} components={components} />

            <p className=" text-lg text-white dark:text-gray-300">
              {footerimage.body}
            </p>
          </span>
          //  </Link>
        ))}
    </div>
  );
}
export default Footerimage;
