import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import { PortableText } from "@portabletext/react";
import Allsblock from "./Allsblock.js";
import Footerfacial from "./Footerfacial.js";
import { Helmet } from "react-helmet-async";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const components = {
  types: {
    image: (props) => (
      <figure>
        <img src={urlFor(props.value.asset).width(200).url()} />
      </figure>
    ),
  },
};

export default function Allblocks() {
  const [allPostsData, setAllPosts] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"] | order(rating){
        title,
        body,
        rating,
        text,
        slug,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  return (
    <div id="facial">
      <Helmet>
        <title>Facial - Diabella</title>
        <link rel="canonical" href="https://diabella.nl/facial/" />
        <meta property="og:locale" content="nl_NL" />
        <meta
          name="description"
          content="Bij de schoonheidsbehandelingen gebruiken we hypo-allergene en natuurlijke Academie Scientifique de Beaute producten. De behandelingen worden op uw huidtype afgestemd en we geven advies voor thuisverzorging."
        />
        <meta property="og:url" content="https://diabella.nl/facial/" />
        <meta property="og:site_name" content="Diabella" />
      </Helmet>
      <svg id="svg2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#EBDDD9"
          fillOpacity="1"
          d="M0,288L60,256C120,224,240,160,360,144C480,128,600,160,720,160C840,160,960,128,1080,106.7C1200,85,1320,75,1380,69.3L1440,64L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
        ></path>
      </svg>
      <Allsblock />
      <svg id="svg3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#EBDDD9"
          fillOpacity="1"
          d="M0,320L48,293.3C96,267,192,213,288,176C384,139,480,117,576,128C672,139,768,181,864,202.7C960,224,1056,224,1152,202.7C1248,181,1344,139,1392,117.3L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>

      <section className="text-mariels-default bg-mariel-default body-font">
        <div className="container px-20 py-24 mx-auto">
          <div className="flex flex-wrap -m-4">
            {allPostsData &&
              allPostsData.map((post, index) => (
                //<Link to={"/" + post.slug.current} key={post.slug.current}>
                <div key={index} className="p-4 lg:w-1/3 sm:w-1/2 w-full">
                  {post.mainImage && (
                    <img
                      src={urlFor(post.mainImage).width(200).url()}
                      className="w-10 h-10 rounded-full"
                      alt={post.title}
                    />
                  )}

                  <h2 className="font-medium text-3xl title-font  text-mariels-default mb-4 text-sm text-center sm:text-left">
                    {post.title}
                  </h2>

                  <PortableText value={post.text} components={components} />
                  <h2 className="font-medium text-3xl title-font  text-mariels-default mb-4 mt-4 text-sm text-center sm:text-left">
                    {post.body}
                  </h2>
                </div>

                // </Link>
              ))}
          </div>
        </div>
      </section>
      <svg id="svg4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#EBDDD9"
          fillOpacity="1"
          d="M0,256L80,261.3C160,267,320,277,480,234.7C640,192,800,96,960,53.3C1120,11,1280,21,1360,26.7L1440,32L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
        ></path>
      </svg>
      <Footerfacial />
    </div>
  );
}
