import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import { PortableText } from "@portabletext/react";
import doc from "./doc.json";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

export default function Allsblock() {
  const [allPostsData, setAllPosts] = useState(null);

  const components = {
    types: {
      image: (props) => (
        <figure>
          <img src={urlFor(props.value.asset).width(200).url()} />
        </figure>
      ),
    },
    block: {
      // Ex. 1: customizing common block types
      h1: ({ children }) => (
        <p className="fade-in text-mariels-default mb-8 leading-relaxed">
          {children}
        </p>
      ),
    },
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "facialimage"]{
        title,
        body,
        slug,
        text,
        image,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  return (
    <div className="test">
      {allPostsData &&
        allPostsData.map((facialimage, index) => (
          // <Link to={"/" + klip.slug.current} key={klip.slug.current}>

          <section
            key={index}
            className="container belang text-mariel-default bg-mariel body-font"
          >
            <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
              {facialimage.mainImage && (
                <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                  <img
                    loading="lazy"
                    src={urlFor(facialimage.mainImage).url()}
                    className="fade-in object-cover object-center rounded"
                    alt={facialimage.title}
                  />
                </div>
              )}

              <div className="fade-in ok-in lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-mariels-default mb-4">
                  {facialimage.title}
                </h1>

                <PortableText
                  value={facialimage.text}
                  components={components}
                />

                <span>
                  <p className="text-mariels-default">{facialimage.body}</p>
                </span>
              </div>
            </div>
          </section>

          //  </Link>
        ))}
    </div>
  );
}
