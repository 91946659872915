import React from "react";
import logo from "../assets/image/logodiabella.png";
import kp from "../assets/image/lg.png";
import logol from "../assets/image/opi.png";
import { useInView } from "react-intersection-observer";

const Footerend = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  return (
    <div ref={ref} className={`boxx ends   ${inView ? "activess" : ""}`}>
      <footer className="color1  body-font">
        <div className="color1">
          <div className="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
            <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
              <div className="w-28 h-28 md:w-28 md:h-28 inline-flex items-center justify-center rounded-full">
                <img className="logo w-28 md:w-28" src={logo} />
              </div>
            </a>
            <p className="text-sm text-black sm:ml-6 sm:mt-0 mt-4">
              © 2023 Diabella —
              <span className="text-black-600 ml-1">@mariel</span>
            </p>
            <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
              <a
                href="https://www.academiebeaute.com/"
                target="_blank"
                className="text-gray-500"
              >
                <img className="logo" src={kp} width="300px" />
              </a>

              <a
                href="https://www.opi.nl/"
                target="_blank"
                className="ml-3 text-gray-500"
              >
                <img className="logo" src={logol} width="154px" />
              </a>
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
};
export { Footerend };
