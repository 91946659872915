import React, { useEffect, useState } from "react";
import mail from "../../assets/image/mail.svg";
import { useInView } from "react-intersection-observer";
import sanityClient from "../../client.js";

function Fparttwo() {
  const [allPostsData, setAllPosts] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "mail"]{
        title,
        body,
        slug
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);

    setTimeout(() => {
      setLoading(true);
    }, 1000);
  }, []);
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  if (!loading) {
    return <div></div>;
  }
  return (
    <div ref={ref} className={`boxx ${inView ? "activess" : ""}`}>
      <div className="  w-20 h-20 inline-flex items-center justify-center rounded-full bg-mariel-default text-indigo-500 mb-5 flex-shrink-0">
        <a
          href="mailto:info@diabella.nl"
          target="_blank"
          className="hover:invert"
        >
          <img className="mailme" src={mail} width="50px" />
        </a>
      </div>
      <div className="flex-grow">
        {allPostsData &&
          allPostsData.map((mail, index) => (
            //<Link to={"/" + post.slug.current} key={post.slug.current}>
            <span key={index}>
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                {mail.title}
              </h2>

              <p className="leading-relaxed text-base">{mail.body}</p>
            </span>
            // </Link>
          ))}
      </div>
    </div>
  );
}
export default Fparttwo;
